import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: "",
  data: {},
};

const activeFolderSlice = createSlice({
  name: "activeFolder",
  initialState: initialState,
  reducers: {
    set(state, action) {
      state.id = action.payload.id;
      state.data = action.payload.data;
    },
    reset(state) {
      state.id = '';
      state.data = {};
    },
  },
});

export const activeFolderActions = activeFolderSlice.actions;

export default activeFolderSlice.reducer;
