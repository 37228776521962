import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { db } from "../firebase";
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { modalActions } from "../store/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlus,
  faPencilAlt,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import Pill from "./UI/Pill";
import { useNavigate } from "react-router-dom";
import { activeFolderActions } from "../store/ActiveFolder";

const Admin = () => {
  const dispatch = useDispatch();
  const smallPlusIcon = <FontAwesomeIcon icon={faCirclePlus} size="1x" />;
  const editIcon = <FontAwesomeIcon icon={faPencilAlt} size="2x" />;
  const trashIcon = <FontAwesomeIcon icon={faTrashCan} size="2x" />;
  const navigate = useNavigate();
  const [lists, setFolders] = useState([]);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    dispatch(activeFolderActions.reset());
  });
  useEffect(() => {
    const colq = query(
      collection(db, `users/${user.localId}/folders`),
      orderBy("title", "asc")
    );
    onSnapshot(colq, (querySnapshot) => {
      setFolders(
        querySnapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }))
      );
    });
  }, [user.localId]);

  const pickFolderClickHandler = (data) => {
    dispatch(activeFolderActions.set(data));
    navigate("/admin/files");
  };

  const editFolderClickHandler = (data) => {
    dispatch(activeFolderActions.set(data));
    navigate("/list");
  };

  const deleteFromFoldersClickHandler = async (data) => {
    const taskDocRef = doc(db, `users/${user.localId}/folders`, data.id);
    try {
      await deleteDoc(taskDocRef);
    } catch (err) {
      alert(err);
    }
  };

  const openFolderClickHandler = () => {
    dispatch(modalActions.open("addFolder"));
  };

  return (
    <div>
      <h1 className="title">My Folders</h1>
      {lists.length > 0 &&
        lists.map((list, index) => (
          <Pill className="PillWithIcon" id={list + index} key={list + index}>
            <div className="pillContainer">
              <div className="PillTitle">
                <h4 onClick={() => pickFolderClickHandler(list)}>
                  {list.data.title}
                </h4>
              </div>
              <div className="PillIcons">
                <div onClick={() => editFolderClickHandler(list)}>
                  {editIcon}
                </div>
                <div onClick={() => deleteFromFoldersClickHandler(list)}>
                  {trashIcon}
                </div>
              </div>
            </div>
          </Pill>
        ))}
      <div className="bottom_center">
        <div onClick={openFolderClickHandler}>Add Folder {smallPlusIcon}</div>
      </div>
    </div>
  );
};
export default Admin;
