import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { db } from "../firebase";
import { doc, updateDoc} from "firebase/firestore";
import { modalActions } from "../store/Modal";
import { profileActions } from "../store/Profile";
import Form from "./UI/Form";
import styles from "../styles/AdminLoginForm.module.scss";
import CryptoJS from "crypto-js";
const SetAdminLoginForm = () => {
  const adminPinInputRef = useRef();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const profile = useSelector((state) => state.profile.profile);

  const savePin = async (event) => {  
    event.preventDefault();
    const pinEntered = adminPinInputRef.current.value;
    let encrypted = CryptoJS.AES.encrypt(pinEntered, 'U2FsdGVkX1++oeAy3z0juCHAQT/TidZ++GewFKaFQ84=').toString();
    let newProfile = {...profile, pin: encrypted};
    const taskDocRef = doc(db, `profiles`, user.localId);
    dispatch(profileActions.set(newProfile));
    try {
      await updateDoc(taskDocRef, newProfile);
    } catch (err) {
      alert(err);
    }
    dispatch(modalActions.close());
    adminPinInputRef.current.value = "";
  }

  return (
    <Form onSubmit={savePin}>
      <h1 className="title">Enter Pin You Want To Use</h1>
      <input ref={adminPinInputRef} autoFocus className={styles.pinInput} type="number" inputMode="numeric" pattern="[0-9]*"></input>
      <button type="submit">Save</button>
    </Form>
  );
};

export default SetAdminLoginForm;
