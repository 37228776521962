import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";
import MyAdminPage from "./pages/MyAdminPage";
import AdminPage from "./pages/AdminPage";
import AuthPage from "./pages/AuthPage";
import MyListsPage from "./pages/MyListsPage";
import ListPage from "./pages/ListPage";

import "./styles/reset.css";
import "./styles/setup.scss";
import Modal from "./components/UI/Modal";
import MyFilesPage from "./pages/MyFilesPage";

function App() {
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  return (
    <div>
      <Header />
      <div className="main">
        <Routes>
          <Route path="" element={<HomePage />} />
          <Route path="/auth" element={<AuthPage />} />
          {isAuth && <Route path="/profile" element={<MyListsPage />} />}
          {!isAuth && <Route path="/profile" element={<AuthPage />} />}
          {isAuth && <Route path="/list" element={<ListPage />} />}
          {!isAuth && <Route path="/list" element={<AuthPage />} />}
          {isAuth && <Route path="/myadmin" element={<MyAdminPage />} />}
          {(isAuth && isAdmin) && <Route path="/admin" element={<AdminPage />} />}
          {(isAuth && isAdmin) && <Route path="/admin/files" element={<MyFilesPage />} />}
          <Route path="*" element={<Navigate to="" />} />
        </Routes>
        <Modal />
      </div>
      <Footer />
    </div>
  );
}

export default App;
