import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  url: "",
};

const imageSlice = createSlice({
  name: "url",
  initialState: initialState,
  reducers: {
    set(state, action) {
      state.url = action.payload
    },
    close(state) {
      state.url = "";
    },
  },
});

export const imageActions = imageSlice.actions;

export default imageSlice.reducer;
