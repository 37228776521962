import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { authActions } from "../store/Auth";
import { modalActions } from "../store/Modal";
import styles from "../styles/Footer.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faList,
  faSignOutAlt,
  faLock,
} from "@fortawesome/free-solid-svg-icons";

const houseIcon = <FontAwesomeIcon icon={faHouse} size="3x" />;
const listIcon = <FontAwesomeIcon icon={faList} size="3x" />;
const signOutIcon = <FontAwesomeIcon icon={faSignOutAlt} size="3x" />;
const lockIcon = <FontAwesomeIcon icon={faLock} size="3x" />;

const Footer = () => {
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const navigate = useNavigate();
  const authAdminRouteClickHandler = () => {
    if (isAuth) {
      navigate("/profile");
    } else {
      navigate("/auth");
    }
  };

  const adminLogin = () => {
    dispatch(modalActions.open("savePin"));
  }

  const logoutClickHandler = () => {
    dispatch(authActions.logout());
    navigate("");
  };

  return (
    <footer className={styles.mainFooter}>
      <div className={styles.mainFooterItems}>
        <NavLink to="" className={styles.mainFooterItem}>
          {houseIcon}
        </NavLink>
        <div
          onClick={authAdminRouteClickHandler}
          className={styles.mainFooterItem}
        >
          {listIcon}
        </div>
        {isAuth && <div onClick={adminLogin} className={styles.mainFooterItem}>{lockIcon}</div>}

        {isAuth && (
          <div onClick={logoutClickHandler} className={styles.mainFooterItem}>
            {signOutIcon}
          </div>
        )}
      </div>
    </footer>
  );
};

export default Footer;
