import { configureStore } from "@reduxjs/toolkit";

import activeFolderReducer from "./ActiveFolder";
import activeListReducer from "./ActiveList";
import authReducer from "./Auth";
import imageReducer from "./Image";
import modalReducer from "./Modal";
import profileReducer from "./Profile";
import winnerReducer from "./Winner";

const Store = configureStore({
  reducer: {
    activeFolder: activeFolderReducer,
    activeList: activeListReducer,
    auth: authReducer,
    image: imageReducer,
    modal: modalReducer,
    profile: profileReducer,
    winner: winnerReducer,
  },
});

export default Store;
