import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  active: false,
  type: "",
};

const modalSlice = createSlice({
  name: "modal",
  initialState: initialState,
  reducers: {
    open(state, action) {
      state.active = !state.active;
      state.type = action.payload;
    },
    close(state) {
      state.active = !state.active;
      state.type = "";
    },
  },
});

export const modalActions = modalSlice.actions;

export default modalSlice.reducer;
