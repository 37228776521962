import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { doc, setDoc, onSnapshot, query } from "firebase/firestore";
import { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { db, auth } from "../firebase";
import { authActions } from "../store/Auth";
import { profileActions } from "../store/Profile";
import Card from "./UI/Card";
import Form from "./UI/Form";

const AuthForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const emailInputRef = useRef();
  const passwordInputRef = useRef();
  const [isLogin, setIsLogin] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const switchAuthModeHandler = () => {
    setIsLogin((prevState) => !prevState);
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const enteredEmail = emailInputRef.current.value;
    const enteredPassword = passwordInputRef.current.value;
    if (isLogin) {
      await login(enteredEmail, enteredPassword);
      setIsLoading(false);
    } else {
      await signup(enteredEmail, enteredPassword);
      setIsLoading(false);
    }
  };

  const login = async (email, password) => {
    signInWithEmailAndPassword(auth, email, password)
      .then(async (data) => {
        // Signed in
        const loginData = {
          localId: data.user.uid,
          email: data.user.email,
          token: data.user.accessToken
        }
        dispatch(authActions.login(loginData));
        setProfile(loginData.localId);
        navigate("/profile");
        // ...
      })
      .catch((error) => {
        alert(error.message);
        // ..
      });
  };

  const signup = async (email, password) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (data) => {
        // Signed in
        const loginData = {
          localId: data.user.uid,
          email: data.user.email,
          token: data.user.accessToken
        }
        dispatch(authActions.login(loginData));
        await createProfile(loginData.localId);
        await setProfile(loginData.localId);
        navigate("/profile");
        // ...
      })
      .catch((error) => {
        alert(error.message);
        // ..
      });
  };

    const createProfile = async (localId) => {
    try {
      await setDoc(doc(db, "profiles", localId), {
        name: "",
      });
    } catch (err) {
      alert(err);
    }
  };

  const setProfile = async (localId) => {
    const docq = query(doc(db, "profiles", localId));
    onSnapshot(docq, (querySnapshot) => {
      dispatch(profileActions.set(querySnapshot.data()));
    });
  };

  return (
    <div>
      <Card>
        <Form onSubmit={submitHandler}>
          <h1>{isLogin ? "Login" : "Sign Up"}</h1>
          <span className="span">
            <label className="label" htmlFor="email">
              Email
            </label>
            <input
              className="input"
              type="email"
              id="email"
              required
              ref={emailInputRef}
            />
          </span>
          <span className="span">
            <label className="label" htmlFor="password">
              Password
            </label>
            <input
              className="input"
              type="password"
              id="password"
              required
              ref={passwordInputRef}
            />
          </span>
          <span className="span">
            {!isLoading && (
              <button className="button">
                {isLogin ? "Login" : "Create Account"}
              </button>
            )}
            {isLoading && <p>Sending request...</p>}
          </span>
          <span className="span">
            <h5 onClick={switchAuthModeHandler}>
              {isLogin ? "Create new account" : "Login with existing account"}
            </h5>
          </span>
        </Form>
      </Card>
    </div>
  );
};

export default AuthForm;
