import { useSelector } from "react-redux";

import styles from "../styles/Image.module.scss";
const Image = () => {
  const url = useSelector((state) => state.image.url);
  return (
    <div className={styles.Winner}>
      <img alt="bigImage" src={url} />
    </div>
  );
};
export default Image;
