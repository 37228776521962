import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { activeListActions } from "../store/ActiveList";
import { imageActions } from "../store/Image";
import { modalActions } from "../store/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { collection, query, orderBy, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import styles from "../styles/MyFiles.module.scss";

const MyFiles = () => {
  const plusIcon = <FontAwesomeIcon icon={faCirclePlus} size="1x" />;
  const user = useSelector((state) => state.auth.user);
  const activeFolder = useSelector((state) => state.activeFolder);
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  useEffect(() => {
    dispatch(activeListActions.reset());
  });
  useEffect(() => {
    const colq = query(
      collection(db, `users/${user.localId}/folders/${activeFolder.id}/files`),
      orderBy("title", "asc")
    );
    onSnapshot(colq, (querySnapshot) => {
      setFiles(
        querySnapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }))
      );
    });
  }, [user.localId, activeFolder.id]);

  const uploadHandler = () => {
    dispatch(modalActions.open("upload"));
  };

  const fullScreenView = (url) => {
    dispatch(imageActions.set(url));
    dispatch(modalActions.open("image"));
  }

  return (
    <div>
      <h1 className="title">{activeFolder.title}</h1>
      <div className={styles.thumbnails}>
        {files.length > 0 &&
          files.map((file, index) => (
            <img
              alt={file.data.title}
              src={file.data.image}
              key={file.data.title + index}
              onClick={() => fullScreenView(file.data.image)}
            />
          ))}
      </div>
      <div className="bottom_center">
        <div onClick={uploadHandler}>{plusIcon}</div>
      </div>
    </div>
  );
};
export default MyFiles;
