import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  profile: {},
};

const profileSlice = createSlice({
  name: "profile",
  initialState: initialState,
  reducers: {
    set(state, action) {
      state.profile = action.payload
    },
    logout(state) {
      state.profile = {};
    },
  },
});

export const profileActions = profileSlice.actions;

export default profileSlice.reducer;
