// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB-Y5nm9fx9nMY_KvOJOcNE7lR9-qBo9WI",
  authDomain: "icantpick-2e051.firebaseapp.com",
  projectId: "icantpick-2e051",
  storageBucket: "icantpick-2e051.appspot.com",
  messagingSenderId: "298475150170",
  appId: "1:298475150170:web:5030ad4b4d001e39b7b163",
  measurementId: "G-TPFV2WXDEX",
};

// Initialize Firebase and Firestore
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);
export { db, storage, auth };