import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { activeListActions } from "../store/ActiveList";
import { winnerActions } from "../store/Winner";
import { modalActions } from "../store/Modal";
import Card from "./UI/Card";
import Form from "./UI/Form";
import Pill from "./UI/Pill";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import styles from "../styles/Home.module.scss";

const Home = () => {
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  const activeList = useSelector((state) => state.activeList);
  const [options, setOptions] = useState(
    activeList.data.options?.length > 0 ? activeList.data.options : []
  );
  const smallPlusIcon = <FontAwesomeIcon icon={faCirclePlus} size="3x" />;
  const trashIcon = <FontAwesomeIcon icon={faTrashCan} size="2x" />;
  const newOptionInputRef = useRef();

  const addOptionHandler = (event) => {
    event.preventDefault();
    const newOption = newOptionInputRef.current.value;
    setOptions((options) => [...options, newOption]);
    newOptionInputRef.current.value = "";
  };

  const pickOptionClickHandler = () => {
    dispatch(winnerActions.set(options[getRandomInt(options.length)]));
    dispatch(modalActions.open("winner"));
  };

  const resetOptionsClickHandler = () => {
    dispatch(activeListActions.reset());
    setOptions([]);
  };

  const getRandomInt = (max) => {
    return Math.floor(Math.random() * max);
  };

  const deleteFromListClickHandler = (index) => {
    let tempOptions = [...options];
    tempOptions.splice(index, 1);
    setOptions(tempOptions);
  };

  return (
    <div className={styles.HomeContainer}>
      {isAuth && <h1 className="title">{activeList.data.title}</h1>}
      {activeList.data.options?.length > 0 && (
        <button
          onClick={resetOptionsClickHandler}
          className={styles.resetButton}
        >
          Clear
        </button>
      )}
      <div className={styles.OptionsContainer}>
        {options < 1 && <h1 className="title">Enter Options</h1>}
        {options.length > 0 &&
          options.map((option, index) => (
            <Pill
              className="PillWithIcon"
              id={option + index}
              key={option + index}
            >
              <div className="pillContainer">
                <div className="PillTitle">
                  <h4>{option}</h4>
                </div>
                <div className="PillIcons">
                  <div onClick={() => deleteFromListClickHandler(index)}>
                    {trashIcon}
                  </div>
                </div>
              </div>
            </Pill>
          ))}

        <Card>
          <Form onSubmit={addOptionHandler}>
            <span className="span">
              <input
                className="input"
                id="newOption"
                type="text"
                ref={newOptionInputRef}
              />
              <div className={styles.addPlusIcon} onClick={addOptionHandler}>
                {smallPlusIcon}
              </div>
            </span>
          </Form>
        </Card>
      </div>
      {options.length > 0 && (
        <div className={styles.PickButtonContainer}>
          <button
            onClick={pickOptionClickHandler}
            className={styles.PickButton}
            type="button"
          >
            Pick For Me
          </button>
        </div>
      )}
    </div>
  );
};
export default Home;
