import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { db } from "../firebase";
import { collection, addDoc, doc, updateDoc } from "firebase/firestore";
import Card from "./UI/Card";
import Form from "./UI/Form";
import Pill from "./UI/Pill";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";

import styles from "../styles/ListForm.module.scss";
import { useNavigate } from "react-router-dom";

const ListForm = () => {
  const navigate = useNavigate();
  const smallPlusIcon = <FontAwesomeIcon icon={faCirclePlus} size="3x" />;
  const trashIcon = <FontAwesomeIcon icon={faTrashCan} size="2x" />;
  const newOptionInputRef = useRef();
  const activeList = useSelector((state) => state.activeList);
  const titleInputRef = useRef();
  const [options, setOptions] = useState(
    activeList.data.options?.length > 0 ? activeList.data.options : []
  );
  const user = useSelector((state) => state.auth.user);
  const submitButtonLabel =
    activeList.data.options?.length > 0 ? "Update" : "Save";
  const addOptionHandler = (event) => {
    event.preventDefault();
    const newOption = newOptionInputRef.current.value;
    setOptions((options) => [...options, newOption]);
    newOptionInputRef.current.value = "";
  };

  const submitListClickHandler = async (event) => {
    event.preventDefault();
    const title = titleInputRef.current.value;
    const optionList = {
      title: title,
      options: options,
    };
    if (activeList.data.options?.length > 0) {
      updateList(optionList);
      navigate("/profile");
    } else {
      saveNewList(optionList);
    }
    titleInputRef.current.value = "";
    setOptions([]);
  };

  const saveNewList = async (optionList) => {
    try {
      await addDoc(collection(db, `users/${user.localId}/lists`), optionList);
    } catch (err) {
      alert(err);
    }
  };

  const updateList = async (optionList) => {
    const taskDocRef = doc(db, `users/${user.localId}/lists`, activeList.id);
    try {
      await updateDoc(taskDocRef, optionList);
    } catch (err) {
      alert(err);
    }
  };

  const deleteFromListClickHandler = (index) => {
    let tempOptions = [...options];
    tempOptions.splice(index, 1);
    setOptions(tempOptions);
  };

  return (
    <div>
      <input
        placeholder="Enter Title"
        ref={titleInputRef}
        defaultValue={
          activeList.data.options?.length > 0 ? activeList.data.title : ""
        }
        className={styles.TitleInput}
      />
      <div className={styles.OptionsContainer}>
        {options.length > 0 &&
          options.map((option, index) => (
            <Pill
              className="PillWithIcon"
              id={option + index}
              key={option + index}
            >
              <div className="pillContainer">
                <div className="PillTitle">
                  <h4>{option}</h4>
                </div>
                <div className="PillIcons">
                  <div onClick={() => deleteFromListClickHandler(index)}>
                    {trashIcon}
                  </div>
                </div>
              </div>
            </Pill>
          ))}

        <Card>
          <Form onSubmit={addOptionHandler}>
            <span className="span">
              <input
                className="input"
                id="newOption"
                type="text"
                ref={newOptionInputRef}
              />
              <div onClick={addOptionHandler}>{smallPlusIcon}</div>
            </span>
          </Form>
        </Card>
      </div>
      {options.length > 0 && (
        <div className={styles.PickButtonContainer}>
          <button
            onClick={submitListClickHandler}
            className={styles.PickButton}
            type="button"
          >
            {submitButtonLabel}
          </button>
        </div>
      )}
    </div>
  );
};
export default ListForm;
