import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { activeListActions } from "../store/ActiveList";

import {
  collection,
  query,
  orderBy,
  onSnapshot,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { db } from "../firebase";
import Pill from "./UI/Pill";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrashCan } from "@fortawesome/free-solid-svg-icons";

const editIcon = <FontAwesomeIcon icon={faPencilAlt} size="2x" />;
const trashIcon = <FontAwesomeIcon icon={faTrashCan} size="2x" />;

const MyLists = () => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [lists, setLists] = useState([]);
  useEffect(() => {
    dispatch(activeListActions.reset());
  });
  useEffect(() => {
    const colq = query(
      collection(db, `users/${user.localId}/lists`),
      orderBy("title", "asc")
    );
    onSnapshot(colq, (querySnapshot) => {
      setLists(
        querySnapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }))
      );
    });
  }, [user.localId]);

  const pickListClickHandler = (data) => {
    dispatch(activeListActions.set(data));
    navigate("/");
  };

  const editListClickHandler = (data) => {
    dispatch(activeListActions.set(data));
    navigate("/list");
  };

  const deleteFromListClickHandler = async (data) => {
    const taskDocRef = doc(db, `users/${user.localId}/lists`, data.id);
    try {
      await deleteDoc(taskDocRef);
    } catch (err) {
      alert(err);
    }
  };

  return (
    <div>
      <h1 className="title">My Lists</h1>
      {lists.length > 0 &&
        lists.map((list, index) => (
          <Pill className="PillWithIcon" id={list + index} key={list + index}>
            <div className="pillContainer">
              <div className="PillTitle">
                <h4 onClick={() => pickListClickHandler(list)}>
                  {list.data.title}
                </h4>
              </div>
              <div className="PillIcons">
                <div onClick={() => editListClickHandler(list)}>{editIcon}</div>
                <div onClick={() => deleteFromListClickHandler(list)}>
                  {trashIcon}
                </div>
              </div>
            </div>
          </Pill>
        ))}
      <div className="bottom_center">
        <Link to={"/list"}>Add New List</Link>
      </div>
    </div>
  );
};
export default MyLists;
