import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "../store/Modal";
import Form from "./UI/Form";
import styles from "../styles/AdminLoginForm.module.scss";
import AES from "crypto-js/aes";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";
import { authActions } from "../store/Auth";
const AdminLoginForm = () => {
  const adminPinInputRef = useRef();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile.profile);
  const navigate = useNavigate();
  const submitHandler = async (event) => {
    event.preventDefault();
    const pinEntered = adminPinInputRef.current.value;
    if (
      pinEntered ===
      AES.decrypt(profile.pin, "U2FsdGVkX1++oeAy3z0juCHAQT/TidZ++GewFKaFQ84=").toString(CryptoJS.enc.Utf8)
    ) {
      dispatch(authActions.adminLogin());
      navigate("/admin");
    } else if (pinEntered === "0000"){
      navigate("/myadmin");
    } else {
      alert("Incorrect Pin");
    }
    dispatch(modalActions.close());
    adminPinInputRef.current.value = "";
  };

  return (
    <Form onSubmit={submitHandler}>
      <h1 className="title">Enter Admin Pin</h1>
      <input
        ref={adminPinInputRef}
        autoFocus
        className={styles.pinInput}
        type="password"
        inputMode="numeric"
        pattern="[0-9]*"
      ></input>
      <button className="button" type="submit">Login</button>
    </Form>
  );
};

export default AdminLoginForm;
