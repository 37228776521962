import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  winner: "",
};

const winnerSlice = createSlice({
  name: "winner",
  initialState: initialState,
  reducers: {
    set(state, action) {
      state.winner = action.payload
    },
    close(state) {
      state.winner = "";
    },
  },
});

export const winnerActions = winnerSlice.actions;

export default winnerSlice.reducer;
