import Card from "./UI/Card";

const MyAdmin = () => {
  return (
    <Card>
      <h1>Future home of admin page</h1>
    </Card>
      );
};
export default MyAdmin;
