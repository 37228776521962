import { useSelector } from "react-redux";

import styles from "../styles/Winner.module.scss";
const Winner = () => {
  const winner = useSelector((state) => state.winner.winner);
  return (
    <div className={styles.Winner}>
      <h1>{winner}</h1>
    </div>
  );
};
export default Winner;
