import { useSelector, useDispatch } from "react-redux";
import { modalActions } from "../../store/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import AdminLoginForm from "../AdminLoginForm";
import SaveAdminLoginForm from "../SetAdminLoginForm";
import Winner from "../Winner";

import "../../styles/UI/Modal.scss";
import Upload from "../Upload";
import AddFolderForm from "../AddFolderForm";
import Image from "../Image";

const Modal = (props) => {
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.modal);
  const closeIcon = <FontAwesomeIcon icon={faTimes} size="2x" />;

  const close = () => {
    dispatch(modalActions.close());
  };

  if (!modal.active) {
    return null;
  }
  return (
    <div className="modal">
      <div className="modal_content">
      {modal.type === "addFolder" && <AddFolderForm />}
        {modal.type === "admin" && <AdminLoginForm />}
        {modal.type === "savePin" && <SaveAdminLoginForm />}
        {modal.type === "upload" && <Upload />}
        {modal.type === "image" && <Image />}
        {modal.type === "winner" && <Winner />}
        <div className="closeX" onClick={close}>{closeIcon}</div>
      </div>
    </div>
  );
};

export default Modal;
