import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "../store/Modal";
import { db } from "../firebase";
import { addDoc, collection } from "firebase/firestore";
import Form from "./UI/Form";
import styles from "../styles/AdminLoginForm.module.scss";
const AddFolderForm = () => {
  const folderInputRef = useRef();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const addFolderHandler = async (event) => {
    event.preventDefault();
    const folder = 
    {title: folderInputRef.current.value};
    try {
      await addDoc(collection(db, `users/${user.localId}/folders`), folder);
      dispatch(modalActions.close());
    } catch (err) {
      alert(err);
    }
  };

  return (
    <Form onSubmit={addFolderHandler}>
      <h1 className="title">Folder Name</h1>
      <input
        ref={folderInputRef}
        autoFocus
        className={styles.pinInput}
      ></input>
      <button className="button" type="submit">Add Folder</button>
    </Form>
  );
};

export default AddFolderForm;
