import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { storage } from "../firebase";
import { db } from "../firebase";
import { addDoc, collection } from "firebase/firestore";
import { modalActions } from "../store/Modal";
import Card from "./UI/Card";
import styles from "../styles/Upload.module.scss";

const Upload = () => {
  // State to store uploaded file
  const dispatch = useDispatch();
  const [file, setFile] = useState("");
  const user = useSelector((state) => state.auth.user);
  const activeFolder = useSelector((state) => state.activeFolder);
  // progress
  const [percent, setPercent] = useState(0);

  // Handle file upload event and update state
  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  const handleUpload = () => {
    if (!file) {
      alert("Please upload an image first!");
    }

    const storageRef = ref(
      storage,
      `/files/${user.localId}/${activeFolder.data.title}/${file.name}`
    );

    // progress can be paused and resumed. It also exposes progress updates.
    // Receives the storage reference and the file to upload.
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        // update progress
        setPercent(percent);
      },
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
          console.log(url);
          const base64Image = await convertToBase64(file);
          addFile(url, base64Image);
        });
      }
    );
  };

  const convertToBase64 = (basefile) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(basefile);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const addFile = async (url, image) => {
    try {
      await addDoc(
        collection(
          db,
          `users/${user.localId}/folders/${activeFolder.id}/files`
        ),
        { title: file.name, url: url, image: image }
      );
      dispatch(modalActions.close());
    } catch (err) {
      alert(err);
    }
  };

  return (
    <Card className={styles.Upload}>
      <h1>Upload</h1>
      <div>
        <input
          className={styles.uploadInput}
          type="file"
          onChange={handleChange}
          accept=""
        />
        {file !== "" && (
          <div className={styles.uploadButton}>
            <button className="button" onClick={handleUpload}>Upload to Firebase</button>
            <h4>{percent} "% done"</h4>
          </div>
        )}
      </div>
    </Card>
  );
};
export default Upload;
